.custom-table-dem thead {
    background-color: white;  /* Make it opaque */
    z-index: 2;  /* Make sure it's on top */
}

/* Style the individual table header cells */
.custom-table-dem th {
    text-align: left;
    border-bottom: 1px solid #ddd;
    position: sticky;
    top: -19px;
    z-index: 3;  /* Make it even higher to appear on top */
    background-color: white;  /* This is important to make the header cells also opaque */
}
  /* Style the table */
  .custom-table-dem {
    width: 100%;
    font-size: 11px; /* Font size for the entire table */
    margin-top: -19px;
  }
  

/* Style the table container */
.table-container-dem {
    flex: 2;
    border-radius: 8px;
    padding: 1rem;
    background: white;
    max-height: 560px;
    overflow-y: scroll;
    margin-left: -16px;
    overflow: auto;
}


/* Map container */
.map-container {
    flex: 1;
    height: 400px;
    width: 400px;
    border-radius: 8px;
    overflow: hidden;
    align-self: center;  /* Center this particular item vertically */
}

/* Split view container */
.split-view-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    margin-top: 10px;
}

  

  .custom-table-dem th {
    color: #55595c;  /* Font color for headers */

  }
  
  /* Style the table body */
  .custom-table-dem td {
    padding: 4px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 11px;
    line-height: 1.1;
    color: #194480;  /* Font color for body */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* Alternate row background for the first column */
  .custom-table-dem tr:nth-child(even) td:first-child {
    background-color:#DDE7F4;
  }
  
  .custom-table-dem tr:nth-child(odd) td:first-child {
    background-color:#f2f2f2;
  }
  
  /* Style for hover */
  .table-hover tbody tr:hover {
    background-color: #f5f5f5;
  }
  
  .custom-table-dem thead th {
    text-align: left !important;
    background-color: white !important;
    font-size: 11px !important;
    padding: 0;
}