
  .left-panel {
    width: 20%;
    padding: 10px;
  }
  .category-button {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 30px; /* Adjust as needed to create an oval shape */
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    text-decoration: none; /* In case it's styled as a link */
    color: inherit; /* Inherit the text color from the parent */
    outline: none;
  }
  
  .category-button:hover {
    background-color: #f5f5f5; /* Change color on hover */
  }
  
  .category-icon {
    width: 30px; /* Size of the icon */
    height: 30px; /* Size of the icon */
    border-radius: 50%; /* Makes the icon circular */
    object-fit: cover; /* Ensures the image covers the area without being stretched */
    margin-right: 10px;
  }
  
  .category-name {
    font-weight: bold;
  }
  
  .center-panel {
    width:50%;
    padding: 10px;
  }
  
  .right-panel {
    width: 30%;
    padding: 10px;
  }
  