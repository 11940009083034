.bankrapp {
    flex-direction: column; /* Stack children vertically */
}

.header-container {
    align-self: stretch; /* Make sure it stretches across the container */
}

.panel-container {
    display: flex;
    flex-direction: row; /* Stack children horizontally */
}

.left-panel,
.center-panel,
.right-panel {
    min-height: 800px; /* Example fixed height */
}

.left-panel {
    background-color: #f0f2f5;
}

.center-panel {
    background-color:#DDE7F4;
    max-height: 400px; /* Adjust this value based on your layout */
    overflow: auto; 
}

.right-panel {
    background-color:#f0f2f5;
    max-height: 400px; /* Adjust this value based on your layout */
    overflow: auto; 
}
