.app-container-main-dem {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  height: 100%;
}

.search-container-main-dem input::-webkit-input-placeholder {
    color: black;
    font-weight: bold;
    font-size: 20px;
  }
  
  .search-container-main-dem input:-moz-placeholder { /* Firefox 18- */
    color: black;
    font-weight: bold;
    font-size: 20px;
  }
  
  .search-container-main-dem input::-moz-placeholder { /* Firefox 19+ */
    color: black;
    font-weight: bold;
    font-size: 20px;
  }
  
  .search-container-main-dem input:-ms-input-placeholder {
    color: black;
    font-weight: bold;
    font-size: 20px;
  }
  
.search-container-main-dem {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 25px;
    padding: 5px 10px;
    width: 430px !important;
    border: 1px solid black; /* Added this line to set the border */
  }

.search-container-main-dem input {
  border: none;
  outline: none;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 14px;
  width: 100%;
}

.search-container-main-dem button {
  background-color: white;
  border: none;
  color: blue;
  cursor: pointer;
  font-size: 11px;
  margin-left: 5px;
  border-radius: 20px;
  padding: 5px 10px;
}

.results-container-main-dem {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 9999 !important; /* Increase z-index value */
  display: none;
}


.results-container-main-dem > .mytext-main {
  padding: 5px 10px;
  word-wrap: break-word;
}

.results-container-main-dem > .mytext-main:hover {
  background-color: blue;
  color: blue;
}

.search-container-main-dem:focus-within + .results-container-main-dem,
.results-container-main-dem:hover {
  display: block;
}

/* Media query for medium screens */
@media (max-width: 768px) {
  .search-container-main-dem {
    width: 100%;
  }
  .search-container-main-dem button {
    font-size: 8px;
    padding: 2px 2px;
  }

  .search-container-main-dem button {
    margin-left: -30px;
  }
}

/* Media query for extra small screens */
@media (max-width: 400px) {
  .search-container-main-dem {
    width: 100%;
  }

  .search-container-main-dem input,
  .search-container-main-dem button {
    font-size: 12px;
    padding: 4px 8px;
  }

  .search-container-main-dem button {
    margin-left: 3px;
  }

  .results-container-main-dem {
    width: calc(75vw - 25px);
    left: -100px;
    font-size: 10px;
  }

  .app-container-main-dem {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

/* Media query for very small screens (200px-400px) */
@media (min-width: 200px) and (max-width: 400px) {
  .search-container-main-dem {
    width: 80%;
  }

  .search-container-main-dem input{
    font-size: 10px;
    padding: 3px 6px;
  }

  .search-container-main-dem button {
    margin-left: -10px;
    padding: 1px 1px;
    font-size: 7px;
  }
}
