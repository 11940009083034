.navbar {
  background-color: #4169e1 !important;
  position: relative;
  width: 100%;
}

.reduced-height {
  height: 30px;
}

.reduced-height .navbar-brand,
.reduced-height .nav-link {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.reduced-height .navbar-toggler {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-custom .navbar-toggler {
  padding-top: 0;
  padding-bottom: 0;
  height: 100%;
}

.reduced-height .container-fluid {
  display: flex;
  align-items: center;
}

.reduced-height .nav {
  display: flex;
  align-items: center;
}

.reduced-height .navbar-collapse {
  display: flex;
  align-items: center;
}

/* Add this media query for mobile screens */
@media (max-width: 1000px) {
  .reduced-height .navbar-collapse {
    flex-direction: row;

  }

  .reduced-height .nav-link {
    font-size: 11px;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: -10px;
    margin-top: -10px;
  }

  .reduced-height .navbar-brand {
    font-size: 20px;
  }

  .reduced-height .navbar-toggler {
    padding: 5px;
  }
}
