/* General styles */
.error-message {
    color: red;
    font-weight: bold;
    margin: 20px 0;
}
.name-search-wrapper {
    display: flex;
    align-items: center;
    width: 100%;  /* Ensures the container takes up the full width of the parent element */
    gap: 5px;
}

.custom-table-auto thead td {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
}


.custom-table-auto td:not(:first-child) {
    text-align: right !important;
    font-size: 11px;
}

.custom-table-auto tr:not(thead tr) {
    color: #194480;
}


.custom-table-auto td:not(:first-child), 
.custom-table-auto th:not(:first-child) {
    width: 100px;
    min-width: 100px;
}

.table-container {
    overflow-x: auto;
    max-width: 100%;
    position: relative;
}

/* Sticky column styles */
.sticky-column {
    min-width: 275px; /* Adjust this value as needed */
    position: sticky;
    left: 0;
    z-index: 2;
    text-align: left !important;
    font-size: 11px;
}

/* White color for the header sticky column */
thead .sticky-column {
    z-index: 3;
    background-color: white !important;
}


.auto-loans-container .table-container {
    z-index: 1;
    position: relative;
}

/* Modified to make table rows smaller and font size reduced */
.custom-table-auto th, .custom-table-auto td {
    padding: 3px 5px !important;
    line-height: 1.1;
}

.react-select-container, 
.custom-dropdown {
    position: relative;
    z-index: 1000;
}

.name-search-wrapper {
    display: flex;
    align-items: center; /* This vertically aligns items, useful if there's a height mismatch */
    gap: 5px;           /* This provides some space between the name and the search bar */
}

body {
    font-family: Arial, sans-serif;
}

body, h1, p, ul, li {
    margin: 0;
    padding: 0;
}

.dropdown-container-auto {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dropdown-label-auto {
    margin-right: 10px;
    font-weight: bold;
}

.custom-dropdown-auto {
    display: inline-block;   
    margin-right: 20px;
    position: relative;
    z-index: 1000;            /* Add this line */
}

.custom-table-auto tbody tr:nth-child(odd) td:nth-child(1) {
    background-color:#F1F1F3!important;
}
.custom-table-auto tbody tr:nth-child(even) td:nth-child(1) {
    background-color: #DDE7F4 !important;
}

