.app-container-main {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  height: 100%;
}

.search-container-main {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 25px;
  padding: 5px 10px;
  width: 100%;
}

.search-container-main input {
  border: none;
  outline: none;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 14px;
  width: 100%;
}

.search-container-main button {
  background-color: white;
  border: none;
  color: blue;
  cursor: pointer;
  font-size: 11px;
  margin-left: 5px;
  border-radius: 20px;
  padding: 5px 10px;
}

.results-container-main {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 9999 !important; /* Increase z-index value */
  display: none;
}


.results-container-main > .mytext-main {
  padding: 5px 10px;
  word-wrap: break-word;
}

.results-container-main > .mytext-main:hover {
  background-color: blue;
  color: blue;
}

.search-container-main:focus-within ~ .results-container-main,
.results-container-main:hover {
  display: block;
}

/* Media query for medium screens */
@media (max-width: 768px) {
  .search-container-main{
    width: 100%;
  }
  .search-container-main button {
    font-size: 8px;
    padding: 2px 2px;
  }

  .search-container-main button {
    margin-left: -30px;
  }
}

/* Media query for extra small screens */
@media (max-width: 400px) {
  .search-container-main {
    width: 100%;
  }

  .search-container-main input,
  .search-container-main button {
    font-size: 12px;
    padding: 4px 8px;
  }

  .search-container-main button {
    margin-left: 3px;
  }

  .results-container-main {
    width: calc(75vw - 25px);
    left: -100px;
    font-size: 10px;
  }

  .app-container-main {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

/* Media query for very small screens (200px-400px) */
@media (min-width: 200px) and (max-width: 400px) {
  .search-container-main {
    width: 80%;
  }

  .search-container-main input{
    font-size: 10px;
    padding: 3px 6px;
  }

  .search-container-main button {
    margin-left: -10px;
    padding: 1px 1px;
    font-size: 7px;
  }
}
