.app-container-main-pdf {
    font-family: 'Arial', sans-serif;
  }
  
  .search-container-main-pdf {
    display: flex;
    padding-left: 0;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  

  .results-container-main-pdf {
    max-height: 400px;
    overflow-y: auto;
  }

  
  .pdf-wrapper {
    flex: 2;
    display: flex;
  }
  
  .pdf-toc-wrapper {
    flex: 1;
    min-width:80%;
    
  }
  
  .pdf-viewer-wrapper {
    margin-left: -500px;
    height: 950px !important;
    width: 800px;
    z-index: 1;
    align-self: center;  /* Center this particular item vertically */

    
  }

  .search-input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.search-container-main-pdf input {
    flex: 1;
    padding-right: 40px;
    border-radius: 20px;
    border: 1px solid #ccc;
    font-size: 18px;
}

.search-container-main-pdf button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    color: blue;
    cursor: pointer;
    font-size: 11px;
    padding: 0;
    margin: 0;
}

.results-container-main-pdf {
    position: absolute;
    width: 100%;
    top: calc(100% + 5px);
    left: 0;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    font-weight:bold;
    font-size: 13px;
}

.mytext-main-pdf:hover {
    background-color: blue !important;
    color: white; /* Changing text color to white for better visibility with blue background */
}
