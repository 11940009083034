/* CustomTable.css */
.table-responsive{
  width: 100%;
  position: relative;
}

.custom-table th {
  font-size: 11px !important;
  font-weight: bold;
  vertical-align: left ;

}

.custom-table tbody td {
  padding: 3px 5px !important;
  font-size:  11px ;
}

.dropdowns-container {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1000;
}

.table-row-data {
  font-size: 11px;

}

.table-hover tbody tr:nth-child(odd) {
  background-color: #f2f2f2;
}

/* text align number columns to the right */

.custom-table thead th:nth-child(1),
.custom-table tbody td:nth-child(1) {
  min-width: 50px;
  position: sticky;
  left:0;
}

table.custom-table td {
  color: #194480;

}

.custom-table thead th:nth-child(1),
.custom-table thead th:nth-child(2){
  z-index: 3;
  background-color: white;
}

.custom-table tbody td:nth-child(1),
.custom-table tbody td:nth-child(2){
  z-index: 2; 

}

.custom-table thead th:nth-child(2),
.custom-table tbody td:nth-child(2) {
  min-width: 200px !important;
  position: sticky;
  left:50px;
}



.custom-table thead th:nth-child(n+3),
.custom-table tbody td:nth-child(n+3) {
  min-width: 100px;
  z-index: 1;
}

/* For odd rows */
.custom-table tbody tr:nth-child(odd) td:nth-child(1),
.custom-table tbody tr:nth-child(odd) td:nth-child(2) {
    background-color: #F1F1F3 ;
}

/* For even rows */
.custom-table tbody tr:nth-child(even) td:nth-child(1),
.custom-table tbody tr:nth-child(even) td:nth-child(2) {
    background-color: #DDE7F4 ;
}


/* fix table header */

table.custom-table th {
  text-align: left;
}

.custom-select-container {
  min-height: 24px !important;
}

.custom-select__control {
  min-height: 24px !important;
  height: 24px !important;
}

.custom-select__value-container,
.custom-select__indicators {
  height: 100% !important;
}

.custom-select__single-value {
  font-size: 14px !important;
}

.custom-select__menu {
  font-size: 12px; /* Adjust the font size as needed */
}
/* Add this rule to hide the indicator separator */

.custom-select__indicator-separator {
  display: none !important;
}
/* Solution */

.custom-table th, .custom-table td {
    line-height: 1.1;  
}
/*select dropdown */
.segment-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.1rem;
  margin-top: -1rem;
  min-width: 500px; /* Adjust this value as needed */
  flex-wrap: nowrap;
  margin-left: -10px;
}
/*up down arrow */
.sort-indicator {
  margin-left: 5px;
  font-size: 0.8rem;
  position: relative;
  color: lightgrey;
}
.sort-indicator::before,
.sort-indicator::after {
  position: absolute;
  font-family: FontAwesome;
  margin-top:-7px;
}
.sort-indicator::before {
  content: '\f0d7'; /* Unicode for the down arrow */
  top: 0;
}
.sort-indicator::after {
  content: '\f0d8'; /* Unicode for the up arrow */
  bottom: 0;
}
/*search bar stuff*/

.name-search-container {
  display: inline-flex;
 
}

/* CustomTable.css */
.custom-table thead tr th {
  padding:5px;
}

.custom-table-usearch tbody tr td.custom-font-us {
  vertical-align: top !important;
}



