.app-container-ugl {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  height: 100%;
}

.search-container-ugl {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 25px;
  width: 100%;
  position: relative;


}

.search-container-ugl input {
  border: none;
  outline: none;
  border-radius: 20px;
  font-size: .7rem;
}

.search-container-ugl button {
  background-color: white;
  border: none;
  color: blue;
  cursor: pointer;
  font-size: 11px;
  margin-left: 5px;
  border-radius: 20px;
  padding: 5px 10px;
}

.results-container-ugl {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 2000;
  display: none;
}

.results-container-ugl > .result-item-ugl {
  padding: 5px 10px;
  word-wrap: break-word;
}

.results-container-ugl > .result-item-ugl.highlighted {
  background-color: blue;
  color: white;
}

.search-container-ugl:focus-within ~ .results-container-ugl,
.results-container-ugl:hover {
  display: block;
}

.sticky-metric-usearch {
  position: sticky;
  left: 0;
  z-index: 3; 
  background-color: white;
  min-width: 250px !important;
  font-size: 11px !important;

}

.custom-table-usearch tbody tr:nth-child(odd) td:nth-child(n+2) {
  background-color: #f2f2f2 !important;
}

.custom-table-usearch tbody tr:nth-child(even) td:nth-child(n+2) {
  background-color: white!important;
}

.custom-table-usearch thead th:nth-child(n+2),
.custom-table-usearch tbody td:nth-child(n+2) {
  z-index: 1 !important ;
  max-width: 150px !important;
  
}
.custom-font-us{
font-size: 11px;
}


.custom-table-usearch tbody td:nth-child(2) {
  left: 0px !important;

}