.app-container-main-bankr {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70vh;
    text-align: center;
  }
  
  .title-bankr {
    font-size: 1.5em;
    margin-bottom: 1em;
  }
  
  .search-container-main-bankr {
    position: relative;
    margin-top: 20px;
  }
  
  input[type="text"].search-input-bankr {
    width: 300px;
    padding: 10px;
    margin-right: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 20px;
  }
  
  .selected-bank-bankr {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between; /* This will push the 'x' to the end */
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 5px 10px;
    margin-bottom: 10px; /* Or any other value for spacing between items */
}

.selected-bank-bankr h5 {
    margin: 0;
    padding-right: 10px; /* This ensures space between the text and the 'x' */
}

  
  .selected-bank-bankr button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    outline: none;
    position: relative;
  }
  
  
  .selected-bank-bankr button::before,
  .selected-bank-bankr button::after {
    position: absolute;
    background-color: #000; /* Color of the 'X' */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  
  .selected-bank-bankr button::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  
  
  .next-button-bankr {
    padding: 10px 20px;
    margin-top: 20px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
  }
  
  .results-container-main-bankr {
    position: absolute; /* Takes the box out of the normal document flow */
    z-index: 10; /* Any value higher than the elements below to overlay them */
    top: 48%; /* Position it just below the search bar */
    left: 50%;
    transform: translateX(-50%);
    width: 50%; /* Or any width you prefer */
    max-width: 400px; /* Optional: sets a max-width for larger screens */
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px; /* Or any value to get the desired border radius */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Optional: adds a subtle shadow */
    padding: 20px; /* Adjust as needed */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

.results-container-main-bankr div {
    cursor: pointer; /* Change cursor to pointer */
    padding: 10px; /* Add some padding for better visuals */
}

/* Default text color for h5 within result items */
.results-container-main-bankr div h5 {
    color: black; /* Default color for h5 tags */
}

/* Hover effect for result items */
.results-container-main-bankr div:hover {
    background-color: blue; /* Background color on hover */
}

/* Text color change on hover for h5 within result items */
.results-container-main-bankr div:hover h5 {
    color: white; /* Text color for h5 tags on hover */
}