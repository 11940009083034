.table-container-bs {
  width: min(900px,100%-3rem);
  margin-inline:auto;
  overflow: auto;
  max-height: 700px;
}

.custom-table-bs th,
.custom-table-bs td {
  position: relative;
  z-index: 1; 
}

.custom-table-bs th {
  position: sticky;
  top: 0;
  background-color: white !important;
  z-index: 2; 
}

.custom-table-bs th:first-child {
  position: sticky; /* this needed to be added */
  top: 0; /* for vertical sticking */
  left: 0; /* for horizontal sticking */
  background-color: white !important;
  z-index: 3; 
}

.custom-table-bs td:first-child {
  position: sticky;
  left: 0;
  background-color: white;
  color: #194480;
  min-width: 400px;
  z-index: 2; 
}


  table.custom-table-bs td.item-cell .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 350px !important; /* Adjust this value to your desired maximum width, keeping in mind the above width */
    display: inline-block;
    width: 100%;
  }
  
  table.custom-table-bs th,
  table.custom-table-bs td {
    line-height: 1.1; /* Adjust this value to your desired line-height */
    padding: 2px; /* Adjust this value to your desired padding */
    font-size: 11px !important;
  }

  table.custom-table-bs td {
    color: #194480;

  }
  
  .table-row-bs:hover { 

    cursor: pointer;
}

.arrow-icon {
    display: inline-block;
    transition: transform 0.3s ease;
}

.rotated {
    transform: rotate(90deg);
}

.expandable-row {
  cursor: pointer;
}
  
.small-arrow {
  font-size: 75%;
  vertical-align: middle; /* This aligns the smaller arrow vertically with the text */
}

  
