/* General Styles */

.app-container {
    position: relative; /* Allows for positioning of child elements relative to this container */
}

/* Search Bar Styles */

.search-container {
    position: relative;
    display: flex;
    border: 1px solid black;
    border-radius: 20px;
    width: 168px;
    transform: scale(.85) !important;
    margin-left:-15px;

}

.header-font-size {
    font-size:11px !important;
}

.search-container input {
    border: none;
    width: 170px;
    padding: 5px;
    border-radius: 20px 0 0 20px;
    outline: none; /* Removes focus outline */
}
.name-text {
    padding-right: 7px;
}

.search-container button {
    border: none;
    background: transparent;
    padding: 2px 4px;
    cursor: pointer;
    color: blue;
    font-size: 0.8rem;  /* Reduced font size; adjust accordingly */
    margin-left: 46px;   /* Push the button a bit to the right */
    border-radius: 0 10px 10px 0;
}


/* Toast Styles */

.toast {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 800%;
    z-index: 9999; /* This is just an example. You can adjust based on your needs. */
    color: royalblue;
    white-space: pre-line;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Adds a shadow for better distinction */
    opacity: 1 !important;
}

.toast-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.toast-header .me-auto {
    flex-grow: 1;
}

.toast-header small {
    margin-right: 10px;
}

.toast-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px; /* Adjust as necessary */
    overflow-y: auto; /* Make it scrollable */

}

.first-column-caps {
    text-transform: capitalize !important;
}


.table-responsive {
    overflow-x: auto;
    max-width: 100%;
}


/* Results Container Styles */

.results-container {
    font-size: 0.7rem;
    text-overflow: ellipsis;
    white-space: normal;
    max-width: 200px;
    position: absolute;
    background-color: white;
    z-index: 10;
    box-sizing: border-box;
    margin-top: 5px;
    box-shadow: 0px 4px 8px rgba(0,0,0,0.1);
}

.result-item {
    display: block;
    cursor: pointer;
    padding: 5px 10px;
    transition: background-color 0.3s;
}

.result-item:hover {
    background-color: lightgrey;
}

.sticky-column-autosearch {
    position: -webkit-sticky;  /* For Safari */
    position: sticky;
    left: 0; /* To start sticking at the table's left edge */
    z-index: 10; /* To keep it above other content */
    background-color: white !important; /* Or any other color that suits your design */

}
.alternate-row {
    background-color: #f2f2f2 !important;
}

table.custom-table-autosearch td {
    color: #194480;
  
  }