/* Categories.css */

.card-custom {
  height: 100%;
  width: 90%; /* Set the width to 90% to make the cards wider */
  margin-bottom: 1.5rem; /* Add margin to the bottom of each card */
  padding: 0.5rem; /* Add some padding around each card */
  margin-left: auto; /* Center the cards horizontally */
  margin-right: auto;
}

.card-custom .card-body {
  min-height: 90px; /* Add a minimum height to make the cards the same size */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-custom .card-img {
  height: 180px; /* Decrease the height of the card image */
  object-fit: contain;
}

.card-separation {
  margin-bottom: 1.5rem; /* Add margin between cards */
}
.custom-table th:first-child{
  text-align: left;
  padding-left: 0;
  margin-left: 0;
}

.custom-table th:first-child,
.custom-table td:nth-child(1) {
    max-width: 70px;
    overflow: hidden;
    text-overflow: ellipsis; /* This will show dots when the text is too long */
}


