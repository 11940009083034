.custom-table-pdf {
    max-width: 50%; /* Adjust this value to make the table less wide */
    font-size: 11px;
  }
  .custom-table-pdf thead tr th {
    padding:5px;
    padding-left: 0;
    font-size: 11px;

  }
  .custom-table-pdf thead th {
    font-weight: bold;
  }
  
  .custom-table-pdf tbody tr {
    cursor: pointer;
  }
  
  .custom-table-pdf tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .custom-table-pdf tbody tr:nth-child(odd) {
    background-color: #DDE7F4;
  }
  
  .custom-table-pdf tbody td {
    color: #194480;
    line-height: 1.1;
    padding: 3px 5px;
  }
  
  
  .custom-table-pdf tbody tr:hover {
    background-color: #ccc; /* Add your own hover color here */
  }
  
